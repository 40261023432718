// Import the necessary libraries
import React from 'react';
import Marquee from 'react-fast-marquee';
import '../../Assets/RetroStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Define your component
const MyMarqueeComponent = () => {
  return (
    <div className="marquee-wrapper">
      <Marquee gradient={false} loop={0} className='marquee-container'>
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE PLANTS
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE CLOTHES
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />
        {/* Repeat items as needed */}
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE PLANTS
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE CLOTHES
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE PLANTS
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />
        <a href="Donationmain" target="_blank" rel="noopener noreferrer" className="marquee-link">
          DONATE CLOTHES
        </a>
        <FontAwesomeIcon icon={faStar} className="marquee-icon" />

      </Marquee>
    </div>
  );
};

// Export your component
export default MyMarqueeComponent;
