// Import the necessary libraries
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com'; // Import EmailJS library
import '../../Assets/RetroStyle.css'; // Ensure you have this CSS file

function Donateitem() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    address: '',
    pickupLocation: '',
    itemDescription: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_jyulgie', // Replace with your EmailJS service ID
      'template_0otq01f', // Replace with your EmailJS template ID
      e.target,
      '9CrcfFZ_NJnfGhZGH' // Replace with your EmailJS user ID
    )
      .then((result) => {
        console.log(result.text);
        alert('Your donation details have been submitted successfully!');
        setFormData({
          name: '',
          email: '',
          contactNumber: '',
          address: '',
          pickupLocation: '',
          itemDescription: '',
        });
      })
      .catch((error) => {
        console.error(error.text);
        alert('An error occurred while submitting your donation details. Please try again later.');
      });
  };

  return (
    <div className="site-section about-us">
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-md-12 border section-header">
            <h2 className="text-uppercase text-center">DONATION</h2>
            <p> 
              Thank you for choosing to support our cause! Your generosity truly makes a significant difference in the lives of those we serve.
            </p>
            <div className='row contact p-3'>
                <div className="col-md-6">
                    <p>
                         As you are willing to donate items, we greatly appreciate your generosity. Your support is crucial in helping us make a meaningful impact on the lives of those in need. To ensure a smooth donation process, please contact us using the details below. We are here to assist you and provide any necessary guidance.                    </p>
                    <p>
                    Additionally, we ask that you fill out the form provided. This will help us gather important information about your donation, such as the type of items, preferred pickup location, and your contact details. By doing so, we can efficiently coordinate the collection or drop-off of your items and ensure they reach those who need them most.
                    </p>
                    
                    <div  className='text-center border'>
                        <h3>CONTACT US</h3>
                        <p >jppfngo@gmail.com</p>
                        <p >9419189855</p>
                    </div> 
                </div>
            <form onSubmit={handleSubmit} className="custom-form col-md-6">
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactNumber">Contact Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="contactNumber"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="pickupLocation">Pickup Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="pickupLocation"
                  name="pickupLocation"
                  value={formData.pickupLocation}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="itemDescription">Description of Items</label>
                <textarea
                  className="form-control"
                  id="itemDescription"
                  name="itemDescription"
                  rows="4"
                  value={formData.itemDescription}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                Submit
              </button>
            </form>
            </div>

            <p className="mt-4">
              Thank you once again for your kindness and generosity. Your support is the cornerstone of our success and the key to making a lasting impact.
            </p>
          </div>
        </div>    
      </div>
    </div>
  );
}

export default Donateitem;
