import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import '../../Assets/RetroStyle.css'; // Ensure this path is correct

const Footer = () => {
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const fetchVisitorCount = async () => {
  //     try {
  //       const response = await axios.get('/api/visitor-count');
  //       let data=response.data.count
  //       setCount(data-1);
  //     } catch (error) {
  //       console.error('Error fetching visitor count:', error);
  //     }
  //   };

  //   fetchVisitorCount();
  // }, []);
  return (
    <footer className="site-footer retro-style">
      <div className="container">
        <div className="row">
          {/* Contact Information */}
          <div className="col-md-4 mb-4">
            <h3>Contact Us</h3>
            <ul className="list-unstyled">
              <li>
                <span className="d-block">Address:</span>
                OPP. PILLAR NO 28 AKHNOOR ROAD Lane No:7, Talab Tillo, Jammu, Jammu and Kashmir 180001
              </li>
              <li>
                <span className="d-block">Phone:</span>
                9419189855
                
              </li>
              <li>
                <span className="d-block">Whatsapp:</span>
                <a href='https://wa.me/9419189855'>9419189855</a>
                
              </li>
              <li>
                <span className="d-block">Email:</span>
                jppfngo@gmail.com
              </li>
            </ul>
          </div>

          {/* Navigation Links */}
          <div className="col-md-4 mb-4">
            <h3>Quick Links</h3>
            <ul className="list-unstyled">
              <li><a href="/About" className="retro-link">Who we are</a></li>
              <li><a href="/Membership" className="retro-link">Membership</a></li>
              <li><a href="#" className="retro-link">How to help</a></li>
              <li><a href="/Contact" className="retro-link">Contact</a></li>
              <li><a href="#" className="retro-link">Privacy Policy</a></li>
            </ul>
          </div>

        

          {/* Social Media Links */}
          <div className="col-md-4 mb-4">
            <h3>Follow Us</h3>
            <ul className="list-unstyled social">
              <li><a href="https://www.facebook.com/profile.php?id=100006871637090"><FontAwesomeIcon icon={faFacebookSquare} size="2x" /></a></li>
              <li><a href="#"><FontAwesomeIcon icon={faTwitterSquare} size="2x" /></a></li>
              <li><a href="https://www.instagram.com/jppfjammu/"><FontAwesomeIcon icon={faInstagramSquare} size="2x" /></a></li>
              <li><a href="https://www.linkedin.com/in/jammu-province-913b3a31a/"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a></li>
            </ul>
            <div className='visitor'>
              <div className='visitor-tittle'>
                <h5><a>Visitor Count: </a><a href="https://www.hitwebcounter.com" target="_blank">
                <img src="https://hitwebcounter.com/counter/counter.php?page=15075551&style=0024&nbdigits=7&type=ip&initCount=0" title="Counter Widget" Alt="Visit counter For Websites"   border="0" /></a>    </h5>
              </div>
          </div>
          </div>
          
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <p>  Jammu Province People's Forum (JPPF) | Copyright &copy; {new Date().getFullYear()} Design & Developed by Ejunction</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
