import React from 'react';
import logo from '../../Assets/jppglogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import '../../Assets/RetroStyle.css'; // Add this new CSS file for retro styling

function Header() {
  return (
    <div className="retro-header">
      <div className="row  align-items-center ">
        <div className="col-md-2 text-center">
          <img src={logo} alt="Logo" className="img-fluid retro-logo" />
        </div>
        <div className="col-md-8 retro-title">
          <p>Jammu Province People's Forum (JPPF)</p>
        </div>
        <div className="col-md-2 text-center">
          <ul className="  mb-0">
            <li className="list-inline-item">
                <a href="https://www.facebook.com/profile.php?id=100006871637090" target="_blank" rel="noopener noreferrer" className="retro-icon">
                  <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                </a>
              </li>
              
              <li className="list-inline-item">
                <a href="https://www.instagram.com/jppfjammu/" target="_blank" rel="noopener noreferrer" className="retro-icon">
                  <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="retro-icon">
                  <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
                </a>
              </li>
            {/* <li className="list-inline-item">
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="retro-icon">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="header-main">
        <div>
          <img src={logo} alt="Logo" className="img-fluid retro-logo" />
        </div>
        <div className="retro-title">
          <p>Jammu Province People's Forum (JPPF)</p>
        </div>
        {/* <div className=".social-media text-center">
          <ul className="  mb-0">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/profile.php?id=100006871637090" target="_blank" rel="noopener noreferrer" className="retro-icon">
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
            </li>
            
            <li className="list-inline-item">
              <a href="https://www.instagram.com/jppfjammu/" target="_blank" rel="noopener noreferrer" className="retro-icon">
                <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="retro-icon">
                <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
              </a>
            </li>
            {/* <li className="list-inline-item">
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="retro-icon">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </li> 
          </ul>
        </div> */}
      </div>
    </div>
  );
}

export default Header;
