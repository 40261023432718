// Import the necessary libraries
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Assets/RetroStyle.css'; // Ensure you have this CSS file


function Donationmain() {
  return (
    <div className="site-section about-us">
        <div className="container mt-3">
            <div className="row mb-3">
                <div className="col-md-12 border section-header">
                    <h2 className="text-uppercase text-center">DONATION</h2>
                    <p>
                        Thank you for choosing to support our cause! Your generosity truly makes a significant difference in the lives of those we serve.
                    </p>
                    <p>
                        We offer two convenient donation options to suit your preference:
                    </p>
                    <ul>
                        <li><h5>Donate an Amount:</h5> Your financial contributions are crucial in helping us continue our mission. Whether you choose to make a one-time donation or set up a recurring contribution, every bit helps us provide essential services and support to those in need.</li>
                        <li><h5>Donate Items: </h5>We accept a wide variety of items that can directly benefit those we assist. Items we welcome include clothes, plants, utensils, electronic goods, grocery items, and many other useful products. Your in-kind donations help us meet the diverse needs of our community and make a tangible impact.</li>
                    </ul>
                    <p>
                    To get started, simply click on the button below. Your support, whether through financial contributions or item donations, is invaluable and deeply appreciated. Together, we can make a meaningful difference and continue our efforts to bring positive change.
                    </p>
                    <div className='text-center p-3'>
                        <a href="/donation" className="btn btn-secondary m-4">
                            Click to Donate Amount
                        </a>
                        <a href="/donateitem" className="btn btn-secondary m-4">
                            Click to Donate Items
                        </a>
                    </div>
                    <p>
                    Thank you once again for your kindness and generosity. Your support is the cornerstone of our success and the key to making a lasting impact.
                    </p>
                </div>
            </div>    
        </div>
        </div> 
  );
}

export default  Donationmain;
