import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import '../../Assets/RetroStyle.css'; // Ensure the CSS file is imported for retro styling

function CustomNavbar() {
  return (
    <Navbar expand="lg" className="custom-navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="/home" className="nav-link">HOME</Nav.Link>
          <Nav.Link href="/about" className="nav-link">WHO WE ARE</Nav.Link>
          <Nav.Link href="/membership" className="nav-link">MEMBERSHIP</Nav.Link>
          <Nav.Link href="/portfolio" className="nav-link">NEWS & STORIES</Nav.Link>
          <Nav.Link href="/help" className="nav-link">HOW TO HELP</Nav.Link>
          <Nav.Link href="/gallery" className="nav-link">GALLERY</Nav.Link>
          <Nav.Link href="/contact" className="nav-link">CONTACT</Nav.Link>
          <Navbar.Brand href="/Donationmain" className="navbar-brand">
            <Button variant="link" className="logo-btn">
              <span className="donate-text">DONATE NOW</span>
            </Button>
          </Navbar.Brand>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
