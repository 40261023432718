import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Plantationdrive from '../../Assets/img/plantation.jpg';
import { Link } from 'react-router-dom';
import '../../Assets/Style.css';


function Plantation() {
  return (
    <div className="site-section about-us">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 border section-header">
            <div className="text-center">
              <h2>About the JPPF Plantation Initiative</h2>
            </div>
            <img src={Plantationdrive} className='plantationimg my-5'></img>
            <p className="lh-sm text-justify mx-3">
            The JPPF Plantation Initiative is a community-driven effort dedicated to enhancing our environment through extensive tree planting activities. Launched with a vision to combat climate change, improve air quality, and foster biodiversity, this initiative aims to plant thousands of trees across urban and rural areas. Our mission is to create greener spaces that benefit both the environment and the local communities. </p>
            <div className="col-md-12 mt-6">
              <h3>Why Plantation Matters</h3>
              <p>Plantation plays a crucial role in sustaining our planet. Here’s how:</p>
              <ol type='a' className='lh-sm text-justify fs-6'>
                <li><a class="font-weight-bold text-secondary">Climate Regulation:</a>Trees absorb carbon dioxide, helping to mitigate the effects of climate change. Each tree planted by jPPF contributes to reducing the global carbon footprint.</li>
                <li><a class="font-weight-bold text-secondary">Air Quality Improvement:</a>Trees act as natural air filters by trapping pollutants and releasing oxygen. This helps in improving the air we breathe, leading to healthier communities.</li>
                <li><a class="font-weight-bold text-secondary">Biodiversity Support:</a>Plantations create habitats for numerous species of plants, birds, and animals, supporting biodiversity and ecosystem balance.</li>
                <li><a class="font-weight-bold text-secondary">Soil Conservation:</a>Tree roots prevent soil erosion and help maintain soil health, which is essential for agriculture and water quality.</li>
                <li><a class="font-weight-bold text-secondary">Aesthetic and Recreational Value:</a>Green spaces enhance the beauty of our surroundings and provide areas for recreation and relaxation, improving the overall quality of life.</li>
              </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>The Impact of Our Efforts</h3>
              <p>Since its inception, the jPPF Plantation Initiative has:</p>
                <ol type='a' className='lh-sm text-justify'>
                  {/* <li>Planted over 50,000 trees in various regions.</li> */}
                  <li>Engaged thousands of volunteers from diverse backgrounds, fostering community spirit and environmental stewardship.</li>
                  <li>Partnered with local schools, businesses, and governments to ensure the sustainability and growth of planted areas.</li>
                  <li>Witnessed a measurable improvement in local air quality and a boost in biodiversity in areas where trees have been planted.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Get Involved</h3>
              <p>We invite everyone to join us in this noble cause. Whether you’re an individual, a group, or a business, there are numerous ways to get involved:</p>
                <ol type='a' className='lh-sm text-justify'>
                <li><a class="font-weight-bold text-secondary">Volunteer:</a>Participate in our tree planting events and contribute to creating greener spaces.</li>
                <li><a class="font-weight-bold text-secondary">Donate:</a>Support our initiative financially to help us reach more areas and plant more trees.</li>
                <li><a class="font-weight-bold text-secondary">Partner:</a>Collaborate with us to organize plantation drives in your community or workplace.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Inspiring Words</h3>
              <p>Reflect on these famous quotations as we embark on this journey to make our world greener:</p>
                <ol type='a' className='lh-sm text-justify'>
                  <li>"The earth is our mother and we are her children. What we sow, she returns to us manifold."</li>
                  <li>"A tree is known by its fruit, a man by his deeds. A good deed is never lost; he who sows courtesy reaps friendship, and he who plants kindness gathers love." – Saint Basil</li>
                  <li>"The best time to plant a tree was twenty years ago. The next best time is now."</li>
                </ol>
            </div>
            <p className="mb-0">
               For more information about the jPPF Plantation Initiative, upcoming events, or to get involved, please visit our 
                <Link to="/contact" >
                &nbsp;Contact Page
                 </Link> .              
            </p>
            
        </div>
        </div>
          

        

      

        

        
      </div>
    </div>
  );
}


export default Plantation;
