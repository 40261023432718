import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/RetroStyle.css'

// Import your images here
import image1 from '../Assets/img/gallery/gallery1.jpg';
import image2 from '../Assets/img/gallery/gallery9.jpg';
import image3 from '../Assets/img/gallery/gallery2.jpg';
import image4 from '../Assets/img/gallery/gallery3.jpg';
import image5 from '../Assets/img/gallery/gallery4.jpg';
import image6 from '../Assets/img/gallery/gallery5.jpg';
import image7 from '../Assets/img/gallery/gallery6.jpg';
import image8 from '../Assets/img/gallery/gallery7.jpg';
import image9 from '../Assets/img/gallery/gallery8.jpg';
import image10 from '../Assets/img/gallery/gallery10.jpg';
import image11 from '../Assets/img/gallery/gallery11.jpg';
import image12 from '../Assets/img/gallery/gallery12.jpg';
import image13 from '../Assets/img/gallery/gallery13.jpg';
import image14 from '../Assets/img/gallery/gallery14.jpg';

const images = [
  { src: image1, alt: 'Image 1' },
  { src: image7, alt: 'Image 3' },
  { src: image4, alt: 'Image 3' },
  { src: image8, alt: 'Image 4' },
  { src: image5, alt: 'Image 5' },
  { src: image2, alt: 'Image 6' },
  { src: image3, alt: 'Image 6' },
  { src: image6, alt: 'Image 6' },
  { src: image10, alt: 'Image 6' },
  { src: image14, alt: 'Image 6' },
  { src: image12, alt: 'Image 6' },
  { src: image11, alt: 'Image 6' },
  { src: image13, alt: 'Image 6' }
];

function Gallery() {
  return (
    <div className="container mt-3">
      <div className="row">
        {images.map((image, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="card h-100">
              <div className="card-img-container">
                <img className="card-img-top" src={image.src} alt={image.alt} />
              </div>
              <div className="card-body">
                {/* Optionally include title and description if needed */}
                {/* <h5 className="card-title">{image.title}</h5> */}
                {/* <p className="card-text">{image.description}</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
