import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../Assets/Style.css';
import picture1 from '../Assets/img/pavitar singh.jpg'
import picture2 from '../Assets/img/Chairman sir.jpg'
import picture3 from '../Assets/img/avinash.jpg'
import picture4 from '../Assets/img/sample.jpg'
import picture5 from '../Assets/img/ml sharma.jpg'
import picture6 from '../Assets/img/resham.jpg'

function About() {
  return (
    <div className="site-section about-us">
      <div className="container mt-5">
        <div className="row mb-5">
          <div className="col-md-12 text-center border section-header">
            <h2>About Us</h2>
            <p className="lh-sm text-justify">
              The Jammu Province People's Forum (JPPF) was formed on May 11, 2010, by a coalition of nearly all prominent organizations in Jammu Province. The primary goal was to promote the welfare and progress of the residents of Jammu Province to unite and strengthen the voice of Jammu Province.             </p>
            <p className="lh-sm text-justify">
              The organizations recognized that the two regions of Jammu and Kashmir have distinct issues and solutions due to differences in geography, lifestyle,  culture and customs. Political parties often prioritize their own interests, neglecting the common people. Today, the residents of Jammu feel helpless and weak, unsure of how to improve their situation. What needed is a strong, selfless organization that can provide direction and address issues such as youth employment, women's security, tourism development, and the elimination of corruption and discrimination with the main objective to neutralize anti-national forces while maintaining peaceful conduct and brotherhood in Jammu and Kashmir.            
            </p>
            <p className="lh-sm text-justify">
            Most leaders have become self-serving, focusing on their own progress rather than public welfare. Therefore, there is a need for a strong organization, and JPPF aims to fulfill this role. The intellectuals of the Jammu Province have established this organization to focus on the development and welfare of future generations. Today, JPPF is dedicated to uniting the community and contributing to the progress of Jammu Province.            </p>
          </div>
        </div>

        <div className="row mb-5 border section-header">
          <div className="col-md-12">
            <h3>AIM & OBJECTS</h3>
            <ol type='a' className='lh-sm text-justify'>
              <li>To fight for achievement /fulfillment of political/socio-economic aspirations of the people of Jammu Region.</li>
              <li>To create sense of brotherhood, cooperation, mutual harmony, love and affection amongst the different communities of Jammu Province and its general public.</li>
              <li>To unite Jammuites (Jammuites shall mean people residing in Jammu Province including POK Refugees) and to create awareness in Jammuites regarding their rights. To protect Dogra cultural and geographical identity of Jammu region as one unit.</li>
              <li>To hold discussions, discourses speeches etc on subjects of common interest where various people belonging to different communities can exchange expanded views and experiences which may be made available to younger generations for creating cohesive atmosphere.</li>
              <li>To bring the genuine difficulties and grievances into the notice of authorities and get the same redressed.</li>
              <li>To provide platform to Jammuites to raise their voice and carry out struggle for mitigating their problems in the interest of their welfare, progress and protect their rights provided by the constitution.</li>
              <li>To do all such lawful things as are necessary and conducive or incidental to the attainment of the above objects and purposes.</li>
              <li>To adopt peaceful constitutional ways for achievement of aims and objects of the Forum.</li>
              <li>To publish pamphlets, Newsletters from time to time and issue Press releases. All the income, earnings, moveable and immovable properties of the Forum shall be solely utilized and applied towards the promotion of its aims and objectives as set forth in the aims and objects of the Forum and no profit thereof shall be paid or transferred directly or in directly by way of dividends, Bonus, Profit or in any manner whatever to the present or the past members. No member of the Forum shall have any personal claim on any moveable or immovable properties of the form or make any profit whatever.</li>
              <li>To receive grants in aid, subsidies, development aids, donations etc from the centre/state government individual or other bodies whether established within or outside the country and for that purpose to enter into requisite agreement under law.</li>
              <li>To borrow money for carrying out the objectives of the Society from banks and other bodies corporate with or without society.</li>
              <li>To utilize ITES for carrying out various activities (awareness, promotion, elections etc) of the forum.</li>
            </ol>
          </div>
        </div>

        <div className="row mb-5 border section-header">
          <div className="col-md-12">
            <h3>ACHIEVEMENTS</h3>
            <ol type='a' className='lh-sm text-justify'>
              <li>Central University, initially established in Jammu but later shifted to Kashmir, was re-established in Jammu due to JPPF's movement, and it now functions near Rajendra Singh Pura.</li>
              <li>Appointment of local Vice Chancellors in universities after JPPF's struggle.</li>
              <li>Cancellation of the allotment of Maharaja Hari Singh Park land for a hotel, leading to the park's development.</li>
              <li>Movement for establishing AIIMS in Jammu alongside the Bar Association, ensuring the institution's opening in Jammu.</li>
              <li>Celebration of October 26 as the day Jammu and Kashmir merged with India, initiated by JPPF in the year 2009.</li>
              <li>Initiation of celebrating March 16 as the Foundation Day of Jammu and Kashmir.</li>
            </ol>
          </div>
        </div>

        

        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Meet Our Team</h3>
          </div>
          {teamMembers.map((member, index) => (
            <div className="col-md-4 text-center mb-4 " key={index}>
              <div className="team-member">
                <img src={member.image} alt={`${member.name}`} className="img-fluid rounded-circle mb-3" />
                <h4>{member.name}</h4>
                <p className="text-muted">{member.role}</p>
                <p>{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const teamMembers = [
  {
    name: 'SH. PAVITAR SINGH',
    role: 'PRESIDENT',
    description: '9419188772',
    image: picture1
  },
  {
    name: 'SH. M S KATOCH',
    role: 'WORKING PRESIDENT',
    description: '941918855',
    image: picture2
  },
  {
    name: 'SH. RESHAM SINGH',
    role: 'VICE PRESIDENT',
    description: '941918855',
    image: picture6
  },
  {
    name: 'SH. AVINASH BHATIA',
    role: 'SECRETARY',
    description: '9419192595',
    image: picture3
  },
  {
    name: 'SH. RAM RATAN SHARMA',
    role: 'FINANCE SECRETARY',
    description: '9419192595',
    image: picture4
  },
  {
    name: 'SH. M L SHARMA    ',
    role: 'GERNAL SECRETARY',
    description: '9419192595',
    image: picture5
  },
  {
    name: 'SH. BALWAN SINGH',
    role: 'MEMBER',
    // description: '9419192595',
    image: picture4
  },
];

export default About;
