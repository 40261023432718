import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slider1 from '../../Assets/img/slider/Slider4.jpg';
import slider2 from '../../Assets/img/slider/Slider5.jpg';
import slider3 from '../../Assets/img/slider/Slider1.jpg';
import slider4 from '../../Assets/img/slider/Slider3.jpg';
import slider5 from '../../Assets/img/slider/Slider2.jpg';
import slider6 from '../../Assets/img/slider/Slider6.jpg';
import slider7 from '../../Assets/img/slider/Slider7.jpg';
import slider8 from '../../Assets/img/slider/Slider8.jpg';
import slider9 from '../../Assets/img/slider/Slider9.jpg';
import slider10 from '../../Assets/img/slider/Slider10.jpg';

import '../../Assets/RetroStyle.css'; // Ensure this path is correct

const SliderComponent = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className="slider-fullscreen">
      <div>
        <img src={slider1} alt="Slide 1" className="slider-image" />
      </div>
      <div>
        <img src={slider2} alt="Slide 2" className="slider-image" />
      </div>
      <div>
        <img src={slider3} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider4} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider5} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider6} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider7} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider8} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider9} alt="Slide 3" className="slider-image" />
      </div>
      <div>
        <img src={slider10} alt="Slide 3" className="slider-image" />
      </div>
    </Slider>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: 0 }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'none' }}
      onClick={onClick}
    />
  );
};

export default SliderComponent;
