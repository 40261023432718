import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com'; // Import EmailJS library
import Orcode from '../../Assets/jppf qr code.jpg'

function Membershipformf(props) {
    const { formData, handleChange, handleSubmit, onHide } = props;
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Membership Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="custom-form">
            <Form.Group controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>
  
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
  
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
  
            <Form.Group controlId="formPaymentDate">
              <Form.Label>Payment Date</Form.Label>
              <Form.Control
                type="number"
                name="paymentDate"
                value={formData.paymentDate}
                onChange={handleChange}
                required
              />
            </Form.Group>
  
            <Form.Group controlId="formPaymentAmount">
              <Form.Label>Payment Amount</Form.Label>
              <Form.Control
                type="number"
                name="paymentAmount"
                value={formData.paymentAmount}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formmembershipType">
            <Form.Label>Membership Type</Form.Label>
            <Form.Control
              as="select"
              name="membershipType"
              value={formData.membershipType}
              onChange={handleChange}
              required
            >
              <option value="">Select Membership Type</option>
              <option value="organization">Organization</option>
              <option value="individual">Individual</option>
              <option value="student">Student</option>
            </Form.Control>
          </Form.Group>
  
            {/* <Form.Group controlId="formReceipt">
              <Form.Label>Upload Receipt (Max 50 KB)</Form.Label>
              <Form.Control
                type="file"
                name="receipt"
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={handleChange}
                required
              />
            </Form.Group> */}
  
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function Membershipform() {
    const [modalShow, setModalShow] = useState(false);
    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phoneNumber: '',
      paymentDate: '',
      paymentAmount: '',
      membershipType: '',
      receipt: null,
    });
  
    const handleChange = (e) => {
      const { name, files } = e.target;
  
      if (name === 'receipt') {
        const file = files[0];
        const maxFileSize = 50 * 1024; // 50 KB in bytes
  
        if (file && file.size > maxFileSize) {
          alert('File size exceeds 50 KB. Please upload a smaller file.');
          e.target.value = null; // Clear the input field
          return;
        }
      }
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: files ? files[0] : e.target.value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Use EmailJS to send email
      emailjs.sendForm(
        'service_9jdpask', // Replace with your EmailJS service ID
        'template_4kvcddb', // Replace with your EmailJS template ID
        e.target,
        '9CrcfFZ_NJnfGhZGH' // Replace with your EmailJS user ID
      )
        .then((result) => {
          console.log(result.text);
          alert('Your membership details have been submitted successfully!');
          setModalShow(false);
          // Optionally, reset form data here:
          setFormData({
            fullName: '',
            email: '',
            phoneNumber: '',
            paymentDate: '',
            
            paymentAmount: '',
            membershipType:'',
            receipt: null,
          });
        })
        .catch((error) => {
          console.error(error.text);
          alert('An error occurred while submitting your donation details. Please try again later.');
        });
    };
  return (
    <div className="site-section about-us">
      <div className="container mt-3">
        <div className="row mb-3">
          <div className="col-md-12 border section-header">
            <h2  className='text-uppercase' >Membership form</h2>
            <p>Welcome to our membership program! By becoming a member, you support our initiatives and gain access to exclusive benefits. To join, please follow the steps below:</p>
         
        <div className="row mb-3">
          <div className="col-md-6">
            <h4 className='text-uppercase font-weight-bold'>How to get membership</h4>
            <ol>
              <li className='font-weight-bold'>Transfer Funds to Our Bank Account:</li>
              
              <ul>
                    <li>
                      <span className="font-weight-bold">Bank Name:</span> Jammu
                      and Kashmir Bank (J&K Bank)
                    </li>
                    <li>
                      <span className="font-weight-bold">Account Name:</span>{' '}
                      Jammu Province People's Forum
                    </li>
                    <li>
                      <span className="font-weight-bold">Account Number:</span>{' '}
                      0021040100048972
                    </li>
                    <li>
                      <span className="font-weight-bold">IFSC Code:</span>{' '}
                      JAKA0LUXURY
                    </li>
                    <li>
                      <span className="font-weight-bold">Bank Address:</span>{' '}
                      Shalamar Jammu
                    </li>
                  </ul>
                
              
              <li className='font-weight-bold'>Take a Photo or Screenshot of Your Payment:</li>
              <ul>
                <li>Complete the form below with your details. This will help us process your membership and send you a welcome package.</li>
              </ul>
                <li className='font-weight-bold'>Fill Out the Donation Form:</li>
              <ul>
                <li>After filling in all the required details and uploading the proof of payment, click the “Submit” button.</li>
              </ul>
                <li className='font-weight-bold'>Submit the Form:</li>
              <ul>
                <li>After filling in all the required details and uploading the proof of payment, click the “Submit” button.</li>
              </ul>
            </ol>

          </div>  
         
          <div className='col-md-6 '>
                  <img src={Orcode} alt='OR CODE'/>
                </div>

        </div>
        <div className="col-md-12">
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  Click to Donate
                </Button>

                <Membershipformf
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  formData={formData}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Membershipform;
