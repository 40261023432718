import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Component/Pages/Views/Navbar';
import About from './Component/Pages/About';
import Home from './Component/Pages/Home';
import Gallery from './Component/Pages/Gallery';
import Contact from './Component/Pages/Contact';
import Membership from './Component/Pages/Membership';
import Header from './Component/Pages/Views/Header'
import Footer from './Component/Pages/Views/Footer'
import MembershipForm from './Component/Pages/Views/Membershipform';
import Plantation from './Component/Pages/Views/Plantation';
import Cleanliness from './Component/Pages/Views/Cleanliness';
import Kapadabank from './Component/Pages/Views/Kapadabank';
import Donationmain from './Component/Pages/Views/Donatemain'
import Donation from './Component/Pages/Views/Donation';
import Donateitem from './Component/Pages/Views/Donateitem'

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="membership" element={<Membership />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/" element={<Home />} />
          <Route path="/membershipform" element={<MembershipForm/>} />
          <Route path="/plantation" element={<Plantation/>} />
          <Route path="/cleanliness" element={<Cleanliness/>} />
          <Route path="/kapadabank" element={<Kapadabank/>} />
          <Route path="/Donationmain" element={<Donationmain/>} />
          <Route path="/donation" element={<Donation/>} />
          <Route path="/donateitem" element={<Donateitem/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
}

export default App;
