import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Plantationdrive from '../../Assets/img/Clean-India.jpg';
import { Link } from 'react-router-dom';
import '../../Assets/Style.css';


function Plantation() {
  return (
    <div className="site-section about-us">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 border section-header">
            <div className="text-center">
              <h2>About the JPPF Cleanliness Drive</h2>
            </div>
            <img src={Plantationdrive} className='plantationimg my-5'></img>
            <p className="lh-sm text-justify mx-3">
            The JPPF Cleanliness Drive is an initiative aimed at promoting cleanliness and hygiene in our communities. Understanding the 
            importance of a clean environment for overall health and well-being, we strive to educate, engage, and empower individuals to 
            take responsibility for their surroundings. Our mission is to create cleaner, healthier, and more sustainable environments 
            through regular clean-up activities and awareness campaigns.    </p>        
            <div className="col-md-12 mt-6">
              <h3>Why Cleanliness Matters</h3>
              <p>Cleanliness is not just about maintaining a tidy appearance; it has profound impacts on health, environment, and society. Here’s how:</p>
              <ol type='a' className='lh-sm text-justify fs-6'>
                <li><a class="font-weight-bold text-secondary">Health Benefits:</a>A clean environment reduces the risk of diseases by minimizing exposure to harmful bacteria and viruses. Cleanliness helps prevent the spread of infections, leading to healthier communities.</li>
                <li><a class="font-weight-bold text-secondary">Environmental Protection:</a>Proper waste management and reduced littering protect wildlife and natural habitats. A clean environment ensures that ecosystems remain balanced and thrive.</li>
                <li><a class="font-weight-bold text-secondary">Social Well-being:</a>Clean and well-maintained public spaces enhance the quality of life, promoting mental well-being and community pride. It encourages social interactions and recreational activities in safe and pleasant environments.</li>
                <li><a class="font-weight-bold text-secondary">Economic Growth: </a>Clean cities attract tourists and businesses, boosting the local economy. A reputation for cleanliness can lead to increased investment and job creation.</li>
              </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>The Impact of Our Efforts</h3>
              <p>Since its inception, the jPPF Plantation Initiative has:</p>
                <ol type='a' className='lh-sm text-justify'>
                  {/* <li>Planted over 50,000 trees in various regions.</li> */}
                  <li>Involved thousands of volunteers, fostering a sense of community responsibility and teamwork.</li>
                  <li>Collaborated with local governments, schools, and businesses to ensure sustainable waste management practices.</li>
                  <li>Noticed a significant reduction in littering and an improvement in public health and hygiene in participating areas.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Get Involved</h3>
              <p>We invite everyone to join us in making our communities cleaner and healthier. Whether you’re an individual, a group, or a business, there are numerous ways to get involved:</p>
                <ol type='a' className='lh-sm text-justify'>
                <li><a class="font-weight-bold text-secondary">Volunteer:</a>Participate in our clean-up events and help us maintain cleanliness in public spaces.</li>
                <li><a class="font-weight-bold text-secondary">Donate:</a>Support our initiative financially to help us reach more areas and provide necessary cleaning supplies.</li>
                <li><a class="font-weight-bold text-secondary">Partner:</a>Collaborate with us to organize cleanliness drives in your community or workplace.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Inspiring Words</h3>
              <p>Reflect on these famous Indian quotations as we embark on this journey to make our world cleaner:</p>
                <ol type='a' className='lh-sm text-justify'>
                  <li>"Sanitation is more important than independence." – Mahatma Gandhi</li>
                  <li>"Be the change you wish to see in the world." – Mahatma Gandhi</li>
                  <li>"Cleanliness is next to godliness."</li>
                </ol>
            </div>
            <p className="mb-0">
            For more information about the jPPF Cleanliness Drive, upcoming events, or to get involved, please visit our 
                <Link to="/contact" >
                &nbsp;Contact Page
                 </Link> .              
            </p>
            
        </div>
        </div>
          

        

      

        

        
      </div>
    </div>
  );
}


export default Plantation;
