import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/RetroStyle.css'; // Make sure this path is correct for your project structure

function ContactUs() {
  return (
    <div className="site-section contact-us ">
      <div className="container   ">
        <div className="row mb-5  ">
          <div className="col-md-12 text-center">
            <h2 className="section-title">CONTACT US</h2>
            {/* <p className="section-description">For any inquiries, please fill out the form below.</p> */}
          </div>
        </div>
        <div className="row contact p-3">
          <div className="col-md-6">
            <div className="contact-g-info">
              <h3>ALL GENERAL QUERIES</h3>
              <p className="retro-text">jppfngo@gmail.com</p>
              <p className="retro-text">9419189855</p>
              <h3>DONATION RELATED QUERIES</h3>
              <p className="retro-text">jppfngo@gmail.com</p>
              <p className="retro-text">9419189855</p>
              <h3>MEDIA RELATED QUERIES</h3>
              <p className="retro-text">jppfngo@gmail.com</p>
              <p className="retro-text">9419189855</p>
            </div>
          </div>
          <div className="col-md-6">
            <form className="contact-form">
              <h3 className="contact-title">Get in Touch</h3>
              <p className="section-description">For any inquiries, please fill out the form below.</p>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control retro-input" id="name" placeholder="Your Name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control retro-input" id="email" placeholder="Your Email" />
                <label htmlFor="phone">Phone</label>
                <input type="tel" className="form-control retro-input" id="phone" placeholder="Your Phone" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea className="form-control retro-input" id="message" rows="5" placeholder="Your Message"></textarea>
              </div>
              <button type="submit" className="btn btn-retro retro-btn">Send Message</button>
            </form>
          </div>
        </div>
        <div className="row mt-5">
        <div className="col-md-12 text-center">
            <h2 className='font-weight-bold'>VISIT US</h2>
            {/* <p className="section-description">For any inquiries, please fill out the form below.</p> */}
          </div>
            <div className="col-md-4"> 
              <div className="contact-info">
                <div className="media-body ">
                  <h5 className="heading section-title">Address</h5>
                  <p className="retro-text">OPP. PILLAR NO 28 AKHNOOR ROAD Lane No:7, near SANJEEV MARBLES, Talab Tillo, Jammu, Jammu and Kashmir 180001</p>
                </div>
              </div>
            </div>
            <div className="col-md-4"> 
              <div className="contact-info">
                <div className="media-body">
                  <h5 className="heading section-title">Email</h5>
                  <p className="retro-text">jppfngo@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info">
                <div className="media-body">
                  <h5 className="heading section-title">Phone</h5>
                  <p className="retro-text">9419189855</p>
                  <span className="d-block">Whatsapp:</span>
                <a href='https://wa.me/9419189855' className="retro-text">9419189855</a>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
          <div className="col-md-12 text-center">
            <h2 className='font-weight-bold'>FIND US</h2>
            {/* <p className="section-description">For any inquiries, please fill out the form below.</p> */}
          </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0202177593383!2d74.8219139154371!3d32.74693877062305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e85b3812374f7%3A0xc64ec081ebf4ec39!2sJPPF(Jammu%20province%20people's%20forum)!5e0!3m2!1sen!2sin!4v1622750093171!5m2!1sen!2sin"
              width="auto"
              height="500"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              
            ></iframe>
          </div>
      </div>
    </div>
  );
}

export default ContactUs;
