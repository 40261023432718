import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Plantationdrive from '../../Assets/img/kapada.jpg';
import { Link } from 'react-router-dom';
import '../../Assets/Style.css';


function Plantation() {
  return (
    <div className="site-section about-us">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-12 border section-header">
            <div className="text-center">
              <h2>About Kapada Bank</h2>
            </div>
            <img src={Plantationdrive} className='plantationimg my-5'></img>
            <p className="lh-sm text-justify mx-3">
            Kapada Bank is an innovative initiative dedicated to promoting sustainability and providing essential clothing to those in need. Our mission is to collect, recycle, and redistribute gently used clothing, ensuring that everyone has access to adequate attire while reducing textile waste. By fostering a community-driven approach, we aim to create a circular economy where resources are maximized and waste is minimized.</p>
            <div className="col-md-12 mt-6">
              <h3>Why Kapada Bank Matters</h3>
              <p>Clothing is a fundamental human necessity, yet many individuals lack access to sufficient clothing. The fashion industry also contributes significantly to environmental pollution. Kapada Bank addresses these issues through several impactful strategies:</p>
              <ol type='a' className='lh-sm text-justify fs-6'>
                <li><a class="font-weight-bold text-secondary">Supporting the Needy:</a>We collect and distribute clean, wearable clothes to underprivileged individuals and families, ensuring they have the necessary attire for different seasons and occasions.</li>
                <li><a class="font-weight-bold text-secondary">Environmental Sustainability:</a>By recycling and reusing clothes, we reduce the volume of textile waste that ends up in landfills, decreasing environmental pollution and conserving resources.</li>
                <li><a class="font-weight-bold text-secondary">Community Engagement:</a>Kapada Bank encourages community involvement through clothing donation drives and volunteer opportunities, fostering a sense of solidarity and collective responsibility.</li>
                <li><a class="font-weight-bold text-secondary">Educational Outreach:</a>We educate the public about the importance of sustainable fashion practices, promoting a shift towards conscious consumption and eco-friendly lifestyles.</li>
              </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>The Impact of Our Efforts</h3>
              <p>Since its establishment, Kapada Bank has made significant strides:</p>
                <ol type='a' className='lh-sm text-justify'>
                  <li>Planted over 50,000 trees in various regions.</li>
                  <li>Prevented thousands of kilograms of textile waste from ending up in landfills.</li>
                  <li>Engaged thousands of volunteers in various community activities, promoting a culture of giving and sustainability.</li>
                  <li>Partnered with schools, businesses, and local organizations to host clothing drives and awareness campaigns, extending our reach and impact.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Get Involved</h3>
              <p>WWe invite everyone to join us in making a difference. Whether you’re an individual, a group, or a business, there are numerous ways to get involved:</p>
                <ol type='a' className='lh-sm text-justify'>
                <li><a class="font-weight-bold text-secondary">Volunteer:</a>Clear out your wardrobe and donate gently used clothing to Kapada Bank. Your contributions can provide essential support to those in need.</li>
                <li><a class="font-weight-bold text-secondary">Donate:</a>Offer your time to help sort, clean, and distribute clothing, or assist in organizing clothing drives and community events.</li>
                <li><a class="font-weight-bold text-secondary">Partner:</a>Collaborate with us to host clothing drives at your school, workplace, or community center, or sponsor our initiatives to help us expand our reach and impact.</li>
                </ol>
            </div>
            <div className="col-md-12 mt-6">
              <h3>Inspiring Words</h3>
              <p>Reflect on these famous Indian quotations as we embark on this journey to make a positive impact:</p>
                <ol type='a' className='lh-sm text-justify'>
                  <li>"The simplest acts of kindness are by far more powerful than a thousand heads bowing in prayer." – Mahatma Gandhi</li>
                  <li>"The best way to find yourself is to lose yourself in the service of others." – Mahatma Gandhi</li>
                  <li>"It is not how much we give, but how much love we put into giving." – Mother Teresa</li>
                </ol>
            </div>
            <p className="mb-0">
               For more information about the jPPF Plantation Initiative, upcoming events, or to get involved, please visit our 
                <Link to="/contact" >
                &nbsp;Contact Page
                 </Link> .              
            </p>
            
        </div>
        </div>
          

        

      

        

        
      </div>
    </div>
  );
}


export default Plantation;
